<template>
  <div>
    <el-card class="box-carerd">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchForm">
            <el-form-item label="设备IMEI" prop="imei">
              <el-input placeholder="设备IMEI" v-model="formInline.imei"></el-input>
            </el-form-item>
            <el-form-item :label="$t('index.alarm_type')" prop="type">
              <el-select :placeholder="$t('index.alarm_type')" v-model="formInline.type">
                <el-option :label="$t('alarm.enter_fence')" value="1"></el-option>
                <el-option :label="$t('alarm.leaving_fence')" value="2"></el-option>
                <el-option :label="$t('alarm.sos_alarm')" value="3"></el-option>
                <el-option :label="$t('alarm.low_battery')" value="4"></el-option>
                <el-option :label="$t('alarm.temperature_alarm')" value="5"></el-option>
                <el-option :label="$t('alarm.heart_alarm')" value="6"></el-option>
                <el-option :label="$t('alarm.blood_alarm')" value="7"></el-option>
                <el-option :label="$t('alarm.removing_alarm')" value="8"></el-option>
                <el-option :label="$t('alarm.falldown_alarm')" value="9"></el-option>
                <el-option :label="$t('alarm.reminding_alarm')" value="13"></el-option>
                <el-option :label="$t('alarm.trigger_alarm')" value="14"></el-option>
                <el-option :label="$t('alarm.reset_alarm')" value="15"></el-option>
                <el-option :label="$t('alarm.dismantle_alarm')" value="16"></el-option>
                <el-option :label="$t('alarm.pressure_alarm')" value="17"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="date">
              <el-date-picker
                  type="daterange"
                  v-model="formInline.date"
                  :disabled-date="disabledDate"
                  value-format="YYYY-MM-DD"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="searchBtn" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetBtn" type="primary">{{ $t('device.reset') }}</el-button>
              <el-button @click="handleBatchHandle" :disabled="idList.length === 0" type="success" plain
                         v-if="$store.state.pageAction.indexOf('alarm.batch_handle')> -1">
                {{ $t('alarm.batch_handle') }}
              </el-button>
              <!--              <el-button type="primary" @click="exportBtn">导出</el-button>-->
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-table :data="alarmData" border stripe style="width: 100%" @selection-change="handleSelect"
                @sort-change="sortChange">
        <el-table-column type="selection" width="55" :selectable="handleSelectable"></el-table-column>
        <el-table-column :label="$t('index.device')" prop="imei" width="160"></el-table-column>
        <el-table-column :label="$t('index.device_name')" prop="name" width="200" sortable="custom"></el-table-column>
        <el-table-column :label="$t('index.device_type')" prop="device_type" width="200"></el-table-column>
        <el-table-column :label="$t('index.alarm_type')" prop="type_tip" width="200"></el-table-column>
        <el-table-column :label="$t('index.alarm_content')" prop="content" width="200"></el-table-column>
        <el-table-column :label="$t('alarm.alarm_value')" prop="number" width="100"></el-table-column>
        <el-table-column :label="$t('device.device_status')" prop="online" width="100"></el-table-column>
        <el-table-column :label="$t('alarm.desc')" prop="remark" width="100"></el-table-column>
        <el-table-column :label="$t('alarm.handle_time')" prop="read_time" width="200"></el-table-column>
        <el-table-column :label="$t('index.alarm_time')" prop="create_at" width="200"
                         sortable="custom"></el-table-column>
        <el-table-column :label="$t('device.last_comm_time')" prop="update_at" width="200"
                         sortable="custom"></el-table-column>
        <el-table-column :label="$t('device.operating')" width="150" fixed="right">
          <template #default="scope">
            <el-button @click="handleEdit(scope.row)" size="small"
                       v-if="$store.state.pageAction.indexOf('index.positioning')> -1">{{ $t('index.positioning') }}
            </el-button>
            <el-button @click="actionRead([scope.row.id])" :disabled="scope.row.status === 2" size="small"
                       v-if="$store.state.pageAction.indexOf('alarm.handle')> -1">
              {{ $t('alarm.handle') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog center :title="$t('detail.alarm_location')" top="10vh" v-model="mapPop" width="50%">
        <div id="map" style="width:100%;height:400px;"></div>
        <div class="alarmInfo">
          <p>
            <span>{{ $t('device.name') }} :</span>
            {{ alarminfo.name }}
          </p>
          <p>
            <span>设备IMEI :</span>
            {{ alarminfo.imei }}
          </p>
          <p>
            <span>{{ $t('alarm.alarm_value') }} :</span>
            {{ alarminfo.number }}
          </p>
          <p>
            <span>{{ $t('alarm.safe_range') }} :</span>
            {{ alarminfo.safe_range }}
          </p>
          <p>
            <span>{{ $t('index.alarm_type') }} :</span>
            {{ alarminfo.type_tip }}
          </p>
          <p>
            <span>{{ $t('index.alarm_time') }} :</span>
            {{ alarminfo.create_at }}
          </p>
          <p>
            <span>{{ $t('detail.alarm_location') }} :</span>
            {{ alarminfo.location }}
          </p>
        </div>
      </el-dialog>
      <el-dialog center :title="$t('alarm.desc')" top="30vh" v-model="dialogRead" width="50%">
        <el-form>
          <el-form-item
              :label="$t('alarm.desc')"
              label-width="120px"
              required
          >
            <el-input v-model="remark"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogRead = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="handleSubmitRead" type="primary">{{ $t('device.confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-pagination
          :page-size="formInline.size"
          :page-sizes="[10, 20,50]"
          :total="pageTotal"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import alarm from "@/model/alarm";
import AMapLoader from "@amap/amap-jsapi-loader";
import tool from '@/utils/tool'

export default {
  name: "alarm",
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      formInline: {
        type: this.$route.query.type,
        imei: "",
        page: 1,
        size: 10,
        date: "",
        order: {}
      },
      alarminfo: {},
      mapPop: false,
      alarmData: [],
      idList: [],
      dialogRead: false,
      remark: '',
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      orderParam: {
        prop: "",
        order: ""
      }
    };
  },
  async mounted() {
    await this.alarmList();
  },
  methods: {
    async alarmList() {
      this.formInline.order = this.orderParam
      const ret = await alarm.list(this.formInline);
      if (ret.code === 200) {
        this.alarmData = ret.data.list;
        this.pageTotal = ret.data.count;
      }
    },
    sortChange(column) {
      if (column.prop === 'name') {
        this.orderParam.prop = 'u.' + column.prop;
      } else if (column.prop === 'update_at') {
        this.orderParam.prop = 'd.' + column.prop;
      } else {
        this.orderParam.prop = 'a.' + column.prop;
      }
      this.orderParam.order = column.order;
      this.alarmList();
    },
    handleEdit(data) {
      this.alarminfo = data;
      this.mapPop = true;
      AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
          .then(AMap => {
            let map = new AMap.Map("map", {
              center: [data.longitude, data.latitude],
              zoom: 13
            });
            let trafficLayer = new AMap.TileLayer.Traffic({
              zIndex: 2
            });
            let marker = new AMap.Marker({
              icon: this.$store.state.alarm,
              position: [data.longitude, data.latitude],
              title: data.location
            });
            // 将以上覆盖物添加到地图上
            // 单独将点标记添加到地图上
            map.add(marker);
            map.add(trafficLayer); //添加图层到地图
          })
          .catch(e => {
            console.log(e);
          });
    },
    actionRead(idList) {
      this.idList = idList
      this.remark = ''
      this.dialogRead = true
    },
    async handleSubmitRead() {
      if (tool.isEmpty(this.remark)) {
        this.$message.warning(this.$t('alarm.input_desc'))
        return
      }
      const ret = await alarm.read({
        id_list: this.idList,
        remark: this.remark
      });
      if (ret.code === 200) {
        this.$message.success(ret.message)
        this.dialogRead = false
        this.alarmList()
      } else {
        this.$message.warning(ret.message)
      }
    },
    handleBatchHandle() {
      if (this.idList.length === 0) {
        this.$message.warning(this.$t('alarm.select_alarm'))
        return
      }
      this.actionRead(this.idList)
    },
    handleSelect(selection) {
      this.idList = []
      selection.forEach((v) => {
        this.idList.push(v.id)
      })
    },
    handleSelectable(row) {
      return row.status === 1
    },
    searchBtn() {
      this.alarmList();
    },
    resetBtn() {
      this.formInline.imei = "";
      this.formInline.type = "";
      this.formInline.date = [];
      this.alarmList();
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.alarmList();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.alarmList();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}

.alarmInfo {
  margin-top: 20px;
}

.alarmInfo p {
  line-height: 25px;
  letter-spacing: 2px;
}
</style>
